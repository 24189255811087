import { graphql } from "gatsby";
import React from "react";
import EventList from "../components/EventList";
import IdeaList from "../components/IdeaList/IdeaList";
import SiteMetadata from "../components/SiteMetadata";
import Layout from "../layouts/Layout";
import ArticleList from "../components/ArticleList";
import Hero from "../components/Hero";

export default function EventPage(props) {
  return (
    <Layout>
      <SiteMetadata
        title={`Tolle Geschenkideen für ${props.data.item.title} - mit Tipps & Tricks 🤩`}
        description={`Lass dich inspiereren von tollen Geschenkideen ${
          props.data.item.title.endsWith("tag") ? "zum" : "für"
        } ${props.data.item.title}!`}
        image={props.data.item.image.file.url}
      />

      <Hero
        image={props.data.item.image.localFile.childImageSharp.gatsbyImageData}
      >
        <h2 className="my-36 h1 leading-tight tracking-tight text-center ">
          {props.data.item.title}
          <span className="text-yellow-500">.</span>
          <div className="text-xl sm:text-3xl text-white">
            {props.data.item.date}
          </div>
        </h2>
      </Hero>

      <ArticleList event={props.data.item.title} />
      <IdeaList event={props.data.item.title} />
      <EventList items={props.data.events.nodes} />
    </Layout>
  );
}

export const query = graphql`
  query AnlassQuery($title: String!) {
    item: contentfulAnlass(title: { eq: $title }) {
      title
      date(formatString: "DD. MMMM", locale: "de")
      image {
        file {
          url
        }
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    events: allContentfulAnlass(sort: { order: ASC, fields: date }) {
      nodes {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        title
      }
    }
  }
`;
